.screen-container1{
    width: 100vw;
    background-color: white;
}

.header-container1{
    width: 100%;
    height: 73px;
    border: 1px solid #E6E1E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

}
.about-heading{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 2rem;
}

.Heading-about{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.text9{
  width: 100%;
  display: flex;
  padding: 2rem;
  justify-content: center;
}

.patient-module{
    text-align: center;
    font-size: 16px;
}

.visualImage{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.heading4{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem; 
}

.Ellipse-box{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid;
}

.BOX{
    text-align: center;
    justify-content: center;
}

.vision{
    margin-top: 4.5rem;
}

.Sillyimage{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.pink-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    background-color: #FDEAED;
    margin-top: 3rem;
    flex-direction: row;
    align-items: center;
} 

@media (max-width: 991px) {
    .pink-container {
        padding: 50%;
        width: 90%;
    }
}
.welcome-heading{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 2rem;
}

.boximage{
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-doctor{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;

}

.footerContainer{
    border: 1px solid;
    width: 100%;
    position: relative;
    background-color: #E72B4A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

}

.mediaicons{
    display: flex;
    gap: 2rem;
}

.Boxicons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem;
}

.firstfield{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.secondfield{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.about{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.other-fields{
    padding: 2rem;
}

.left-right-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3rem;
}

.left-content{
    display: flex;
    flex-direction: column;
}
 .full-logo{
    display: block;
 }



@media (max-width: 991px) {

    .pink-container {
        flex-direction: column;
        height: auto;
        padding: 1rem;
    }

    .left-right-content {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    .visualImage {
        width: 80%;
    }

    .Boxicons,
    .firstfield,
    .secondfield {
        flex-direction: column;
        align-items: center;
        padding: 1rem 0;
    }

    .mediaicons {
        gap: 1rem;
    }

    .footerContainer {
        flex-direction: column;
        padding: 1rem;

    }

    .about-heading,
    .Heading-about,
    .text9,
    .patient-module {
        padding: 1rem;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .about-heading  {
        font-size: 36px;
        line-height: 48px;
        padding: 1rem;
      }
    .Heading-about,
    .text9,
    .welcome-heading,
    .boximage,
    .about-doctor,
    .heading4,
    .about,
    .patient-module {
        padding: 1rem;
        justify-items: center;
    }

    .footerContainer {
        padding: 0.5rem;
    }

    .first-half{
        width: 100% !important;
    }

    .Boxicons,
    .firstfield,
    .secondfield {
        flex-direction: column;
        gap: 1rem;
    }

    .left-right-content {
        flex-direction: column;
    }

    .pink-container {
        display: table-cell;
        flex-direction: column;
        justify-self: center;
        padding: 1rem 0;
    }

    .Ellipse-box,
    .visualImage,.Sillyimage {
        width:100% !important;
        margin: 0 auto !important;
    }

    .mediaicons {
        flex-direction: column;
        align-items: center;
    }
    .full-logo{
        display: none!important;
     }
}

@media (max-width: 480px) {
    .welcome-heading,
    .about-heading,
    .Heading-about,
    .text9,
    .visualImage,
    .Sillyimage {
        padding: 1rem;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .BOX,
    .about-doctor,
    .heading4,
    .about,
    .patient-module,
    .Ellipse-box,
    .footerContainer {
        padding: 1rem;
        width: 100%;
        text-align: center;
    }

    .mediaicons {
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
    }

}
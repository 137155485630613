.BottomBar-popover-container{
    position: relative;
}

.bottom-bar-popover-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.25rem; /* Adjust as needed */
    margin-right: 0.25rem; /* Adjust as needed */
    outline: none;
  }
  
  .bottom-bar-popover-button-downicon {
    height: 1rem; /* Adjust as needed */
    width: 1rem; /* Adjust as needed */
  }
  
  .botton-bar-transition-enter {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
  }
  
  .botton-bar-transition-enterfrom {
    opacity: 0;
    transform: translateY(0.25rem); /* Adjust as needed */
  }
  

  .botton-bar-transition-enterto {
    opacity: 1;
    transform: translateY(0);
  }
  
  .botton-bar-transition-leave {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 0.15s;
  }

  .botton-bar-transition-leavefrom {
    opacity: 1;
    transform: translateY(0);
  }
  

  .botton-bar-transition-leaveto {
    opacity: 0;
    transform: translateY(0.25rem); /* Adjust as needed */
  }
  

  .bottom-bar-popover-panel-main-container {
    position: absolute;
    left: 50%;
    bottom: 100%;
    z-index: 10;
    margin-top: 0.75rem; /* Adjust as needed */
    width: 18rem; /* Adjust as needed */
    transform: translateX(-50%);
    padding: 1rem; /* Adjust as needed */
  }
 
  .bottom-bar-popover-panel-inner-container-one {
    overflow: hidden;
    border-radius: 0.5rem; /* Adjust as needed */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .bottom-bar-popover-panel-inner-container-two {
    background-color: #4b5563;
    padding-top: 0.25rem; /* Adjust as needed */
    padding-bottom: 0.25rem; /* Adjust as needed */
  }
  

  .bottom-bar-popover-panel-inner-container-three {
    display: flex;
    align-items: center;
    padding: 0.75rem; /* Adjust as needed */
    padding-bottom: 0; /* Remove bottom padding */
  }
  
  .bottom-bar-popover-panel-inner-container-three-paragraph {
    margin-left: 0.75rem; /* Adjust as needed */
    font-size: 0.875rem; /* Adjust as needed */
    color: #4b5563; /* Adjust as needed */
  }
  
  .bottom-bar-popover-panel-inner-container-four {
    display: flex;
    flex-direction: column;
  }
  
  .bottom-bar-popover-panel-inner-container-four-mic-container {
    padding-left: 1.5rem; /* Adjust as needed */
    padding-right: 0.75rem; /* Adjust as needed */
    padding-top: 0.25rem; /* Adjust as needed */
    padding-bottom: 0.25rem; /* Adjust as needed */
    margin-top: 0.25rem; /* Adjust as needed */
    margin-bottom: 0.25rem; /* Adjust as needed */
    color: #fff; /* Adjust as needed */
    text-align: left;
  }

  .bottom-bar-popover-panel-inner-container-four-mic-container-inner {
    background-color: #f3f4f6; /* Adjust as needed */
  }
  
  .bottom-bar-popover-panel-inner-container-four-mic-button {
    display: flex;
    flex: 1;
    width: 100%;
    text-align: left;
  }
  
  .bottom-bar-popover-panel-inner-container-four-mic-button-color{
    background-color: #f3f4f6; /* bg-gray-150 */

  }

  .bottom-bar-popover-panel-inner-container-four-mic-hr {
    border: 1px solid #d1d5db; /* border-gray-50 */
    margin-top: 0.5rem; /* mt-2 */
    margin-bottom: 0.25rem; /* mb-1 */
  }
  

  .bottom-bar-popover-panel-inner-container-five {
    display: flex;
    padding: 0.75rem; /* p-3 */
    padding-bottom: 0; /* pb-0 */
  }
  
  .bottom-bar-popover-panel-inner-container-five-paragraph {
    margin-left: 0.75rem; /* ml-3 */
    font-size: 0.875rem; /* text-sm */
    color: #374151; /* text-gray-900 */
    text-align: center; /* text-center */
  }
  

  .bottom-bar-popover-panel-inner-container-six {
    display: flex;
    flex-direction: column;
  }
  

  .bottom-bar-popover-panel-inner-container-six-speaker-container {
    padding-left: 1.5rem; /* px-6 */
    padding-right: 0.75rem; /* px-3 */
    padding-top: 0.25rem; /* py-1 */
    padding-bottom: 0.25rem; /* my-1 */
    margin-top: 0.25rem; /* my-1 */
    color: #fff; /* text-white */
  }
  

  .bottom-bar-popover-panel-inner-container-six-speaker-container-button {
    display: flex;
    flex: 1;
    width: 100%;
    text-align: left;
  }
  
  .bottom-bar-popover-panel-inner-container-seven{
    display: none;
  }

  .bottom-bar-popover-panel-inner-container-seven-two {
    overflow: hidden; /* overflow-hidden */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem; /* pb-4 */
  }

  .bottom-bar-popover-panel-inner-container-eight {
    border-radius: 0.375rem; /* rounded-md */
    padding: 0.375rem; /* p-1.5 */
    background-color: #000; /* bg-black */
  }

  .bottom-bar-popover-panel-inner-container-eight-paragraph {
    font-size: 1rem; /* text-base */
    color: #fff; /* text-white */
  }
  
//   Button layouts 
.bottom-bar-layout-container {
    display: flex;
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
  }
  
  .bottom-bar-layout-container-dialog{
    position: relative;
  }

  .bottom-bar-layout-container-transition-enter {
    transition-timing-function: ease-out; /* enter="ease-out" */
    transition-duration: 300ms; /* duration-300 */
  }
  
  .bottom-bar-layout-container-transition-enterfrom {
    opacity: 0; /* enterFrom="opacity-0" */
  }

  .bottom-bar-layout-container-transition-enterto {
    opacity: 1; /* enterTo="opacity-100" */
  }

  .bottom-bar-layout-container-transition-leave {
    transition-timing-function: ease-in; /* leave="ease-in" */
    transition-duration: 200ms; /* duration-200 */
  }
  
  .bottom-bar-layout-container-transition-leavefrom {
    opacity: 1; /* leaveFrom="opacity-100" */
  }
  
  .bottom-bar-layout-container-transition-leaveto {
    opacity: 0; /* leaveTo="opacity-0" */
  }

  .bottom-bar-layout-container-transition-inner {
    position: fixed; /* fixed */
    inset: 0; /* inset-0 */
    background-color: rgba(0, 0, 0, 0.25); /* bg-black bg-opacity-25 */
  }
  

  .bottom-bar-layout-container-transition-two-main {
    position: fixed; /* fixed */
    inset: 0; /* inset-0 */
    overflow-y: hidden; /* overflow-y-hidden */
  }

  .bottom-bar-layout-container-transition-two-main-inner {
    display: flex; /* flex */
    height: 100%; /* h-full */
    align-items: flex-end; /* items-end */
    justify-content: flex-end; /* justify-end */
    text-align: center; /* text-center */
  }
  
  .bottom-bar-layout-container-transition-two-main-inner-dialog {
    width: 100vw; /* w-screen */
    transform: none; /* transform */
    overflow: hidden; /* overflow-hidden */
    background-color: #4b5563; /* bg-gray-800 */
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25); /* shadow-xl */
    transition-property: all; /* transition-all */
  }
  
  
  .bottom-bar-layout-container-transition-two-main-inner-two {
    display: grid; /* grid */
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); /* container */
    background-color: #4b5563; /* bg-gray-800 */
    padding-top: 1.5rem; /* py-6 */
  }
  
  .bottom-bar-layout-container-transition-two-main-inner-three {
    display: grid; /* grid */
    grid-template-columns: repeat(12, minmax(0, 1fr)); /* grid-cols-12 */
    gap: 0.5rem; /* gap-2 */
  }
  
  .bottom-bar-layout-container-transition-two-main-inner-four {
    display: grid; /* grid */
    align-items: center; /* items-center */
    justify-items: center; /* justify-center */
  }
  
  .bottom-bar-layout-container-transition-two-main-inner-four-one {
    grid-column: span 7; /* col-span-7 */
  }
  
  @media (min-width: 640px) {
    .bottom-bar-layout-container-transition-two-main-inner-four-one {
      grid-column: span 5; /* sm:col-span-5 */
    }
  }
  
  @media (min-width: 768px) {
    .bottom-bar-layout-container-transition-two-main-inner-four-one {
      grid-column: span 3; /* md:col-span-3 */
    }
  }

  .bottom-bar-layout-container-transition-two-main-inner-four-two {
    grid-column: span 4; /* col-span-4 */
  }
  
  @media (min-width: 640px) {
    .bottom-bar-layout-container-transition-two-main-inner-four-two {
      grid-column: span 3; /* sm:col-span-3 */
    }
  }
  
  @media (min-width: 768px) {
    .bottom-bar-layout-container-transition-two-main-inner-four-two {
      grid-column: span 2; /* md:col-span-2 */
    }
  }

  .bottom-bar-layout-container-two {
    display: none; /* hidden */
  }
  
  @media (min-width: 768px) {
    .bottom-bar-layout-container-two {
      display: flex; /* md:flex */
    }
  }
  
  @media (min-width: 1024px) {
    .bottom-bar-layout-container-two {
      padding-bottom: 2rem; /* pb-2 */
      padding-left: 0.5rem; /* px-2 */
      padding-right: 0.5rem; /* px-2 */
    }
  }
  
  @media (min-width: 1280px) {
    .bottom-bar-layout-container-two {
      padding-left: 0.75rem; /* lg:px-2 */
    }
  }
  
  @media (min-width: 1536px) {
    .bottom-bar-layout-container-two {
      padding-left: 1.5rem; /* xl:px-6 */
    }
  }
  
  
  .bottom-bar-layout-container-two-inner {
    display: flex; /* flex */
    flex: 1; /* flex-1 */
    align-items: center; /* items-center */
    justify-content: space-evenly; /* justify-center */
  }
  
  .bottom-bar-layout-container-two-inner-two {
    display: flex; /* flex */
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
  }
  
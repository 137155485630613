.radio-select{
    // border: 1px solid;
    width: 100%;
    margin-top: 1rem;
}

.apply-clear-bttns{
    // border: 1px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
.spacing {
    margin-top: 100px;
}
.login-head {
    // text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    // margin-left: 35%;
    // margin-right: 35%;
}
.logo1 {
    // align-items: center;
    // padding: 150px 10px;
    // padding-top: 150px;
    // padding-bottom: 0px;
    // display: block;
    // margin-left: auto;
    // margin-right: auto;
    // width: 50%;
    // margin-left: 200px;
    // margin-top: 70px;
    // vertical-align:bottom;
    text-align: center;
    margin-top: 4%;
}

.field-center {
    padding: 10px 75px 10px 0px;
    // padding-top: 150px;
    // padding-bottom: 0px;
    // display: block;
    margin-left: 10%;
    margin-top: 3%;
    // margin-right: auto;
    // width: 10%;
}

.register-photo form .btn-block {
    padding-top: 8px !important;
    padding-right: 16px !important;
    padding-bottom: 8px !important;
    padding-left: 16px !important;
    color: white;
    position: relative;
    right: 30px;
}

.already {
    margin: auto;
    justify-content: center;
    display: contents;
    margin-top: 10px;
    font-size: medium;
}

.whole {
    margin-left: 0px;
}

.forgotpassword {
    margin: auto;
    display: contents;
    margin-top: 10px;
    font-size: medium;
    color: #e72b4a;
}

.text-center {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 0px;
    margin-top: 40px;
    margin-left: auto;
    text-align: center;
}

.mobile {
    margin: auto;
    margin-top: 10px;
    display: contents;
    font-size: medium;
    color: #e72b4a;
}

.link {
    color: #e72b4a;
    margin-top: inherit;
}

.searchbar-modal-image {
    width: 30%;
    height: 90%;
    margin: 2%;
    border-radius: 8px;
}

.searchModal-main-container {
    width: "45%";
    margin: "2px";
    height: "8rem";
}

.search-card-text-container {
    width: 65%;
    padding: 1%;
    height: 80%;
    display: flex;
    flex-direction: column;
}

.search-card-text-container > span > p {
    line-height: 10%;
}

.search-card-text-container > span:first-of-type > p {
    font-weight: 700;
    font-size: 14px;
}


.search-card-text-container > span:not(:first-child) > p {
    margin: 0;
    font-family: Poppins,sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
    color: #000000;
    font-size: 12px;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.096px;
}
.adminlabs_main_container{
    width: 100%;
    height: 100%;
    // background-color: red;
}

.flex-space-between{
    display: flex;
    justify-content: space-between;
}

.working-days-container{
    width: 50%;
}

.text-align-left{
    text-align: left;
    text-align: -webkit-left;
}

.text-align-right{
    text-align: right;
    text-align: -webkit-right;
}

.working-days-container > div > h1,h2,h3{
    color: #313033;
    font-family: Poppins;
    // font-size: 1.2em;
    font-style: normal;
    font-weight: 500;
    line-height: 5px;
    text-wrap: wrap;
    margin: 15px 0;
}

.working-days-container > div > p{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight:  400;
    line-height: 24px;
    color: #AEAAAE;
}

.working-days-container > div > p{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight:  400;
    line-height: 24px;
    color: #AEAAAE;
}

.working-days-container > div > h4{
    font-family: Poppins;
    font-size: 1.2em;
    font-style: normal;
    font-weight:  400;
    line-height: 10px;
    color: #AEAAAE;
    margin: 15px 0;
}


.first-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px; /* Add spacing between elements */

}

.first-container-inner-two{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.description-container{
    margin-top: 1.5%;
}

.heading-container > div > h1{
    margin-left: 1%;
}

.table-container-inner-one{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
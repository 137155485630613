.register-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // Ensure the container spans the full viewport height
    background-color: #f5f5f5; // Adjust as needed
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 500px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
  
    @media (max-width: 991px) {
      width: 90%; // Make the form take more width on smaller screens
      box-shadow: none; // Optionally remove shadow for a cleaner look
      padding: 15px; // Adjust padding for smaller screens
    }
  }
.resend-otp {
    margin-top: 3%;
    color: #e72b4a;
}

#contaiern-2{
    // border: 2px solid red;
    height: 90%;
    display: flex;
    justify-content: center;    
    align-items: center;
    background: #ffff;
}

@media (max-width: 991px) {
    // #contaiern-2 {
    //     flex-direction: column;
    //     padding: 20px; 
    //     height: auto; 
    // }

    #container-2-inner {
        width: 90%; 
        max-width: 500px;
        margin: 0 auto;
        text-align: center; 
        padding: 10px;
    } 
    .form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px; 
    }
    #contaiern-2{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#container-2-inner{
    // border: 2px solid black;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#otp-box-container{
    display: flex;
    
    flex-direction: column;
    align-items: center;
}

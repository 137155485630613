.search-date-filter{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tran-doc-table{
    width: 100%;
    margin-top: 6rem;
}
.Stepper{
  display: flex;
  // border: 1px solid;
  justify-content: center;
  margin-top: 3rem;
  }
  
  .title1{
      margin-top: -1rem;
  }
  
  .Srvice-details{
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      // justify-content: space-between;
      // gap: -16rem;
      // border: 1px solid;
  }
  
  .nxt-btn{
      // border: 1px solid;
      width: 100%;
      margin-top: -27rem;
  }
  
  .back-btn{
      margin-top: 2.5rem;
      margin-left: -7em;
  }
  
  .title2{
      // margin-top: 1rem;
      display: flex;
      // border: 1px solid;
      width: 100%;
      justify-content: center;
      margin-top: -1.5rem;
  }
  
  .step-back{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9rem;
      // border: 1px solid;
      width: 100%;
  }
  
#app{
height: 100vh;
}
  
.mainBox1{
 width: 100%;
 height: 68vh;
 display: flex;
 flex-direction: column;
 align-items: center
//    border: 1px solid;
}   

.heading1{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content:center;
  margin-top: 3rem;
}

.Text-fields{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.Text-fieldscss{
display: flex;
justify-content: center;
}


.reg-date{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}


.heading{
  width: 100%;
  display: flex;
  justify-content:center;
  margin-top: 3rem;
}

.Text-fields1{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content:center;
  gap: 1.5rem;
  margin-top: 1rem;
}

.other-fields1{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content:center;
  margin-top: 1rem;
}

.Date-of-birth1{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content:start;
  margin-top: 1rem;
}

.gender1{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content:center;
  margin-top: 1rem;
}
.Degree{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Doc-heading{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
}

.reg-date1{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 3%;
}

.indian-reg-no{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.Registration-date{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 3%;
}

.skip-btn{
  display: flex;
  // border: 1px solid;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;

}

.sve-btn{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.imge-cont{
  // border: 1px solid;
  width: 100%;
  height: 85vh;
}

.card-cont1{
  // border: 1px solid;
  width: 100%;
  // height: 70vh;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.done-btn1{
  margin-top: 1rem;
}

.gender2{
  // border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
  // margin-top: 1.5rem;
}

.FrameBox1{
  width: 100%;
  // border: 1px solid;
  padding: 1rem;
}

.undraw-img2{

}

@media only screen and (max-width: 768px) {
.mainBox1 {
  text-align: center;
}

.heading1,
.Doc-heading,
.heading {
  margin-bottom: 20px;
}

.Text-fields1 .MuiTextField-root,
.other-fields1 .MuiTextField-root,
.Date-of-birth1 .MuiTextField-root,
.Degree .MuiTextField-root,
.gender1 .MuiTextField-root,
.Text-fields .MuiTextField-root,
.reg-date .MuiTextField-root,
.indian-reg-no .MuiTextField-root,
.Registration-date .MuiTextField-root,
.last-image .MuiBox-root {
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
}

.Date-of-birth1 .MuiFormControl-root,
.reg-date1 .MuiFormControl-root {
  width: 100%;
  max-width: 300px;
}

.stepper {
  width: 100%;
}

.sve-btn .CustomButton-root,
.skip-btn .CustomButton-root
 {
  width: 100%;
  max-width: 360px;
}

.FrameBox1 img {
  width: 100%;
  height: auto;
}

.imge-cont .card1 {
  text-align: center;
}

.back-btn .MuiButton-root{
width: 100%;
margin-left: 4rem;
}
}

@media only screen and (max-width: 768px) {
  .card-cont1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .last-image {
    margin-bottom: 20px;
  }

  .greetings1,
  .note1 {
    text-align: center;
    margin-bottom: 15px;
  }

  .done-btn1 {
    width: 100%;
    max-width: 270px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

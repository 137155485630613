// @import "../../../static/scss/base/variables";
// @import "../../../static/scss/main.scss";

.register-photo {
    background: #f1f7fc;
    padding: 0px 0px 0px 0px;
}
.form-group {
    align-items: center;
    margin-left: 250px;
    width: 360px;
}
.form-group .buttoncss {
    width: 30px;
}
.register-photo .image-holder {
    display: table-cell;
    width: 720px;
    height: 900px;
    background-image: linear-gradient(359.75deg, #e73a56 10.46%, rgba(231, 58, 86, 0) 99.85%),
        url(https://media.istockphoto.com/id/1200980392/photo/medical-concept-of-asian-beautiful-female-doctor-in-white-coat-with-stethoscope-waist-up.jpg?s=612x612&w=0&k=20&c=nD_1Tn11kWcMZwZfnyA-lYAvNKcBeoEK_KLObBnN6Jg=);
    mix-blend-mode: normal;

    background-size: cover;
}
.logo {
    text-align: center;
    margin-top: 110px;
}

.register-photo .form-container {
    display: table;
    max-width: 1440px;
    height: 900px;
    width: 1440px;
    margin: 0 auto;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.register-photo form {
    display: table-cell;
    width: 4px;
    background-color: #ffffff;
    padding: 40px 60px;
    color: #505e6c;
    width: 720px;
    height: 900px;
}

@media (max-width: 991px) {
    .register-photo form {
        padding: 40px;
    }
    .register-photo .image-holder {
        padding: 100px;
    }
}

.register-photo .text-center {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 0px;
    margin-top: 40px;
    margin-left: auto;
    text-align: center;
}

/* .register-photo form .form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 0px;
  height: 40px;
  
} */

/* .register-photo form .form-check {
  font-size: 13px;
  line-height: 20px;
}
 */
.register-photo form .btn-block {
    background: #e72b4a;
    margin-left: 200px;
    border-radius: 100px;
    gap: 8px;
    width: 170px;
    height: 48px;
    border: none;
    padding: 8px 16px;
    box-shadow: none;
    margin-top: 42px;
    text-shadow: none;
    outline: none !important;
}

.register-photo form .btn-primary:hover,
.register-photo form .btn-primary:active {
    background: #e72b4a;
}

.register-photo form .btn-primary:active {
    transform: translateY(1px);
}

/* .register-photo form .already {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
  
}
 */

.card .content {
    margin-top: 20px;
    margin-left: 120px;
}
.card label.box {
    margin-top: 12px;
    padding: 10px 12px;
    display: flex;
    box-sizing: border-box;
    border: 2px solid #e6e1e5;
    border-radius: 16px;
    cursor: pointer;

    width: 360px;
    height: 96px;
    left: 828px;
    top: 335.02px;
}
#one:checked ~ label.first,
#two:checked ~ label.second,
#three:checked ~ label.third {
    border-color: #e73a56;
    background: #ffffff;
}
.card label.box:hover {
    background: #ffffff;
}
.card label.box .circle {
    width: 24px;
    height: 24px;

    border: 2px solid #787579;

    background: #ccc;

    display: inline-block;
    margin-right: 15px;
    border-radius: 50%;
    transition: all 0.25s ease;
    box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
}
#one:checked ~ label.first .circle,
#two:checked ~ label.second .circle,
#three:checked ~ label.third .circle {
    border-color: #e73a56;
    background: #fff;
}
.card label.box .plan {
    display: flex;
    width: 100%;
    align-items: center;
}
.card input[type="radio"] {
    display: none;
}

.selectprofiletype {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .component-library {
        margin: 20px 50px;
        text-align: center;

        .items {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 10px;
        }
    }
}

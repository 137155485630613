.resend {
    // margin-left: 40%;
    margin-top: 3%;
    color: #e72b4a;
}
.form-group5 {
    margin-left: 37%;
    margin-top: 30%;
}

.logo1 {
    margin-top: 20px;
}

.register-photo{
    box-sizing: border-box !important;
    height: 100vh !important;
    width: 100vw !important;
    margin: 0px !important;
    padding: 0px !important;
    overflow-x: hidden;
}

.form-container{
    width: 100% !important;
    height: 100% !important;
}

.image-holder{
    width: 50% !important;
    height: 100% !important;
}

// .form-inner-container-two{
//     width: 100%;
//     height: 100%;
// }
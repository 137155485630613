// @import "../../../static/scss/base/variables";
// @import "../../../static/scss/main.scss";


.register-photo {
    background: #f1f7fc;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-direction: column; 
    padding: 2rem;
}

.form-group {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
}

.form-group .buttoncss {
    width: 100%;
    max-width: 1.875rem;
}

.register-photo .image-holder {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 50%;
    max-height: 100%;
    background-image: linear-gradient(359.75deg, #e73a56 10.46%, rgba(231, 58, 86, 0) 99.85%),
        url(https://media.istockphoto.com/id/1200980392/photo/medical-concept-of-asian-beautiful-female-doctor-in-white-coat-with-stethoscope-waist-up.jpg?s=612x612&w=0&k=20&c=nD_1Tn11kWcMZwZfnyA-lYAvNKcBeoEK_KLObBnN6Jg=);
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
}

.logo {
    text-align: center;
    margin-top: 2%;
}


.btn-continue {
    min-width: 90%;
    margin: 1% auto;
    background: #e72b4a;
    color: #ffffff;
    text-align: center;
    border: none;
    border-radius: 2rem;
    padding: 0.625rem 1.25rem;
    font-size: 1rem !important;
    box-sizing: border-box;
} 

.button {
    display: block;
}

@media (max-width: 991px) {
    .register-photo form {
        padding: 0px;
    }
    .register-photo .image-holder {
        padding: 50%;
        display: none !important;
        width: auto;
    }
    .component-library {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }
}  

.text-center  {
    font-size: 1.25rem;
    line-height: 1.2em;
    margin-top: 2% auto 0;
    text-align: center;
}

.register-photo form .btn-block {
    background: #e72b4a;
    margin-left: 200px;
    border-radius: 100px;
    gap: 8px;
    width: 170px;
    height: 48px;
    border: none;
    padding: 8px 16px;
    box-shadow: none;
    margin-top: 42px;
    text-shadow: none;
    outline: none !important;
}

.register-photo form .btn-primary:hover,
.register-photo form .btn-primary:active {
    background: #e72b4a;
}

.register-photo form .btn-primary:active {
    transform: translateY(1+px);
}

.card .content {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.card label.box {
    margin-top: 12px;
    padding: 0.625rem 0.75rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid #e6e1e5;
    border-radius: 16px;
    cursor: pointer;
    max-width: 90%;
}

#one:checked ~ label.first,
#two:checked ~ label.second,
#three:checked ~ label.third {
    border-color: #e73a56;
    background: #ffffff;
}

.card label.box:hover {
    background: #ffffff;
}

#one:checked ~ label.first .circle,
#two:checked ~ label.second .circle,
#three:checked ~ label.third .circle {
    border-color: #e73a56;
    background: #fff;
}

.card label.box .plan {
    display: flex;
    width: 100%;
    align-items: center;
}

.card input[type="radio"] {
    display: none;
}

// .component-library{
//     display: flex !important ;
//     flex-direction: column !important;
//     align-items: center !important;
//     flex-wrap: wrap !important;
//     margin-top: 2rem;
// }


.NavBar-Container > a {
    text-decoration: none;
    margin: 0.7%;
    border: 1px solid #e72b4a;
    padding: 4px 20px;
    border-radius: 100px;
    color: #e72b4a;
    cursor: pointer;
}

.NavBar-Container a.active {
    background-color: #e72b4a;
    color: white;
}


.NavBar-Container-one > div > a {
    text-decoration: none;
    margin: 0.7%;
    border: 1px solid #e72b4a;
    padding: 4px 20px;
    border-radius: 100px;
    color: #e72b4a;
    cursor: pointer;
}

.NavBar-Container-one > div a.active {
    background-color: #e72b4a;
    color: white;
}


.horizontal-scroll-container {
    width: 100%;
    height: fit-content;
    display: flex;
    // justify-content: center;
}

.horizontal-scroll-container > div {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

.horizontal-scroll-container > div::-webkit-scrollbar {
    display: none;
  }

.horizontal-scroll-container > div > button {
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (min-width: 620px) {
    .horizontal-scroll-container > div {
        /* background-color: #717171;  */
        // justify-content: center;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
    }
}

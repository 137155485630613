.screen-cntr1 {
    width: 100vw;
    height: 100vh;
    background-color: white;
    box-sizing: border-box;
    overflow-x: hidden;
}

.header-container1 {
    width: 100%;
    height: 56px;
    border: 1px solid #e6e1e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}
.first-row-content{
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    // margin-right: 2%;
    width: 100% !important;
}
.EcareBtn{
    width: 80% !important;
    margin: 10% !important;
}
.secondrowimage{
    width: 80% !important;
}

.imgEcare{
    width: 30% !important;
    // margin-left: 40% !important;
  }
  .text2{
    font-size: 1.9rem !important;
  }
  .text3{
    font-size: 1.9rem !important;
  }
  .text4{
    font-size: 1.9rem !important;
  }
  .ac{
    font-size: 1.9rem !important;
  }
  .td{
    font-size: 1.9rem  !important;
  }
  .tdd{
    font-size: 1.2rem !important;
  }
  .stepsdescr{
    font-size: 1.2rem !important;
  }
  .stepsdescr2{
    font-size: 1.2rem !important;
}
.stepsdescr3{
    font-size: 1.2rem !important;
}
.sdescr{
    font-size: 1.2rem !important;
}
.sdescr2{
    font-size: 1.2rem !important;
}
.sdescr3{
    font-size: 1.2rem !important;
}

@media (min-width:320px) and (max-width:768px) {
  .imgEcare{
    width: 40% !important;
  }  
  .EcareText{
    font-size: 1.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }
  .text2{
    font-size: 1.5rem !important;
  }
  .EcareBtn{    
    // width: 50% !important;
    text-align: center !important;
    width: 80% !important;
    margin: 10% !important;
  }
//   .mainImgEcare {
//     position: relative !important; /* Ensure positioning context for the pseudo-element */
// }

// .mainImgEcare::before {
//     content: '' !important; /* Required for pseudo-elements */
//     position: absolute !important; /* Absolute positioning */
//     top: 0 !important;
//     left: 0 !important;
//     width: 100% !important;
//     height: 100% !important;
//     background: url(../HomeImages/rectangle11.png) no-repeat center center !important;
//     background-size: cover !important; /* Ensure the image covers the entire element */
//     opacity: 0.5 !important; /* Optional: gives a semi-transparent overlay effect */
//     z-index: -1 !important; /* Place it behind the actual content */
// }

  .stepstitle{
    font-size: 1.5rem !important;
  }
  .stepsdescr{
    font-size: 1.2rem !important;
  }
  .stepsdescr2{
    font-size: 1.2rem !important;
    
}
.stepsdescr3{
    font-size: 1.2rem !important;
}
.sdescr{
    font-size: 1.2rem !important;
}
.sdescr2{
    font-size: 1.2rem !important;
}
.sdescr3{
    font-size: 1.2rem !important;
}
.text3{
    font-size: 1.4rem !important;
}
.text4{
    font-size: 1.4rem !important;
}
.ac{
    font-size: 1.5rem !important;
}
.acdescr{
    font-size: 1rem !important;
}
.td{
    font-size: 1.4rem !important;
}
.tdd{
    font-size: 1rem !important;
}
}


@media (max-width: 769px) {
    .header-container1 img {
        display: none;
    }
    .imgEcare{
        display: none !important;
      } 
     
    }



.about-heading {
    width: 100%;
    display: flex;
    justify-content: center;
} 
/* Small screens (tablets) */
@media (max-width: 768px) {
    .about-heading {
        padding: 10px;
        height: auto;
        overflow-x: visible; /* Adjust overflow for smaller screens */
    }
}

/* Extra small screens (phones) */
@media (max-width: 485px) {
    .about-heading {
        padding: 5px;
        height: auto;
        width: 100%;
    }
}

.Heading-about {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.text9 {
    // border: 1px solid;
    width: 100%;
    display: flex;
    padding: 2rem;
}

.patient-module {
    width: 100%;
    padding: 2rem;
}

.visualImage {
    width: 100%;
    display: flex;
    justify-content: center;
}

.heading4 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.Ellipse-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid;
    // margin-top: 5rem;
}

.BOX {
    text-align: center;
    justify-content: center;
}

.vision {
    margin-top: 4.5rem;
}

.Sillyimage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pink-container {
    // border: 1px solid;
    width: 100%;
    height: 632px;
    background-color: #fdeaed;
    margin-top: 3rem;
}

.welcome-heading {
    display: flex;
    width: 100%;
    justify-content: center;
    // margin-top: 1rem;
    padding: 2rem;
    // border: 1px solid;
}

.boximage {
    width: 100%;
    display: flex;
    justify-content: center;
    // border: 1px solid;
}
@media (max-width:320px){
    .boximage{
        display:none !important;
    }
}
.about-doctor {
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-top: 2rem;
    padding: 2rem;
}

.footerContainer {
    // border: 1px solid;
    width: 100%;
    position: relative;
    background-color: #e72b4a;
    display: flex;
    flex-direction: column;
}
/* Small screens (phones) */
@media (max-width: 768px) {
    .footerContainer {
        flex-direction: column;
    }
}

/* Extra small screens (very small phones) */
@media (max-width: 480px) {
    .footerContainer {
    flex-direction: column;
    }
}

.mediaicons {
    display: flex;
    gap: 2rem;
}

.Boxicons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem;
}

.firstfield {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.secondfield {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.about {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.other-fields {
    padding: 2rem;
    // gap: 2rem;
}

.other-content {
    display: show;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
    position: relative;
    align-items: center;
}
  
.burger-menu {
    cursor: pointer;
    display: none !important;
    width:35px;
    height:30px;
    position:fixed;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    left:50px;
}
.burger-menu img {
    width: 30px; /* Adjust size */
    height: 30px; /* Adjust size */
    display: block; 
}

@media (max-width: 768px) {
    .burger-menu {
        display: block !important;
        width:35px;
        height:30px;
        // position: fixed;
        // border-bottom: 1px solid;
        //  top: 4rem;
        z-index: 9999;
        background: #ffff;
        // width: 100%;
        left: 0;
        display: flex;
       flex-direction: column;
}
}

    .other-header-content {
        display: block;
        display:flex;
        align-items: center;
        gap: 1rem;
    }
    @media (max-width:768px){
        .other-header-content{
            display:none;
        }
    }

    .other-header-content.show {
        display: block;
        margin-top: 15rem;
        z-index: 9999;
        position:fixed;
        background-color: #ffff;
        display: flex;
        flex-direction: column;
        width: 100%;
        
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    .title-heading {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        font-size: 1.5em; 
        font-weight: 500;  
        line-height: 1.8em;

    }
    
@media (max-width: 769px) {
    .title-heading {
        font-size: 1em !important;
        font-weight: 500 !important;
        line-height: 0.4em !important;
    }
    .first-row-content {
        font-size: 1.2em !important;
        font-weight: 500 !important;
        line-height: 1.3em !important;
    }
    .steps-title {
        font-size: 1em !important;
        font-weight: 500 !important;
    }
    .steps-data {
        font-size: 1em !important;
        font-weight: 700 !important;
    }
    .second-side-content {
        font-size: 1em !important;
        font-weight: 700 !important;
    }
    .About-content {
        font-size: 1em !important;
        font-weight: 700 !important;
    }
    .title-descr {
        font-size: 1em !important;
        font-weight: 700 !important;
    }
}

    
 
    .steps-title {
        // width: 100%;
        // display: flex;
        // justify-content: center;
        // margin-top: 5rem;
        // font-size: 3rem;
        // font-weight: 700;
        display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    
    //     @media (max-width: 768px) {
    //         font-size: 2rem;
    //         font-weight: 600;
    //     }
    // }
    }

.Steps-Images {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
}
/* Medium screens (tablets) */
@media (max-width: 769px) {
    .Steps-Images {
        display: none !important;
    }
}

.steps-description {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.steps-data {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    font-size: 2rem; /* Default */
    font-weight: 400;
}
@media screen and (max-width: 768px) {
    .steps-description,
    .steps-data {
        border: 2px solid #ffff; 
        padding: 1rem; 
        max-width: 100%; 
        overflow: hidden; 
        margin: 0 auto; 
    }
}


.second-side-content {
    // display: flex;
    // flex-direction: column;
    gap: 2rem;
    background-color: white;
    font-size: 2em; 
    font-weight: 700;
}
@media (max-width:768px){
    .second-side-content{
        font-size: 1em;
        font-weight: 0.4em;
    }
}


.second-row-content {
    width: 100%;
    // display: flex;
    justify-content: center;
    gap: 7rem;
    align-items: center;
    margin-top: 6rem;
    font-size: 2rem; /* Default */
    font-weight: 400;
}

.About-content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 7rem;
    background-color: white;
    font-size: 2rem; /* Default */
    font-weight: 400;
}

.about-descript {
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: white;
}

.box-cont {
    width: 100%;
    display: flex;
    // border: 1px solid;
    background-color: #fdeaed;
    height: 700px;
    align-items: center;
    justify-content: center;
    gap: 8rem;
}
/* Media query to hide the image on smaller screens */
@media (max-width: 850px) {
    .box-cont img {
        display: none !important;
    }
}

.title-descr {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 2rem; /* Default */
    font-weight: 400;
}

.red-footer {
    width: 100%;
    justify-self: center;
    justify-self: baseline;
    display: flex;
    flex-direction: column;
   
    background-color: #e51635;
    // height: 400px;
    padding: 3rem;
  
}

.first-ali {
    width: 100%;
   display: flex;
   align-items: center;
    margin-top: 1rem;
    margin-left: 5rem;
}

.first-half {
   width: 40%;
   display: flex;
   justify-content: center;
   padding-bottom: 10rem;
   padding-right: none;
   
}

.half-align{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    padding-right: 5rem;
    padding-top: 5rem;
    padding-left: none;
    margin-right: 20rem;
}
.hcf-pr {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    // margin-right: 2rem;
}

.about-us {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    // margin-left: 6.5rem;
}

.social-icons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 7rem;
    padding: 2rem;
}


.other-header-content-two{
    display: none;
}


@media (min-width: 767px) and (max-width: 967px) {
    .logoImgLg {
      width: 100% !important;
      max-width: 120px !important; 
    }
    .btn {
      font-size: 14px !important;
    }
    .Cus_Btn {
      width: 110px !important;
    }
  }
  

   

// @import "../../../static/scss/base/variables";
// @import "../../../static/scss/main.scss";

.register-photo {
    background: #f1f7fc;
    display: flex;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    flex-direction: column;
    padding: 2rem;
}

.form-group {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.form-group .buttoncss {
    width: 100%;
    max-width: 1.875rem;
}

.register-photo .image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    background-image: linear-gradient(359.75deg, #e73a56 10.46%, rgba(231, 58, 86, 0) 99.85%),
        url(https://media.istockphoto.com/id/1200980392/photo/medical-concept-of-asian-beautiful-female-doctor-in-white-coat-with-stethoscope-waist-up.jpg?s=612x612&w=0&k=20&c=nD_1Tn11kWcMZwZfnyA-lYAvNKcBeoEK_KLObBnN6Jg=);
    mix-blend-mode: normal;
    background-size: cover;
    background-position: center;
    mix-blend-mode: normal;
    box-sizing: border-box;
}

.logo {
    text-align: center;
    margin-top: 15px;
    max-width: 100%;
}

.register-photo .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    padding: 1px;
    box-sizing: border-box;
}

.register-photo form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    padding: 40px 60px;
    color: #505e6c;
    height: auto;
    border-radius: 8px; 
    box-sizing: border-box
}

.btn-continue {
    min-width: 90%;
    margin: 1% auto;
    background: #e72b4a;
    color: #ffffff;
    text-align: center;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 1rem !important;
    box-sizing: border-box;
} 

.button {
    display: block;
}

@media (max-width: 991px) {
    .register-photo form {
        padding: 0px;
    }
    .register-photo .image-holder {
        padding: 50%;
        display: none !important;
        width: auto;
    }
    .component-library {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }
}

.register-photo .text-center {
    // font-size: 1.2em;
    line-height: 1.2em;
    margin-top: 4% auto 0;
    text-align: center;
}


.register-photo form .btn-block {
    background: #e72b4a;
    margin-left: 200px;
    border-radius: 100px;
    gap: 8px;
    width: 170px;
    height: 48px;
    border: none;
    padding: 8px 16px;
    box-shadow: none;
    margin-top: 42px;
    text-shadow: none;
    outline: none !important;
}

.register-photo form .btn-primary:hover,
.register-photo form .btn-primary:active {
    background: #e72b4a;
}

.register-photo form .btn-primary:active {
    transform: translateY(1px);
}

.card .content {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.card label.box {
    margin-top: 12px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid #e6e1e5;
    border-radius: 16px;
    cursor: pointer;
    max-width: 90%;
}

#one:checked ~ label.first,
#two:checked ~ label.second,
#three:checked ~ label.third {
    border-color: #e73a56;
    background: #ffffff;
}

.card label.box:hover {
    background: #ffffff;
}

.card label.box .circle {
    width: 24px;
    height: 24px;
    border: 2px solid #787579;
    background: #ccc;
    display: inline-block;
    margin-right: 15px;
    border-radius: 50%;
    transition: all 0.25s ease;
    box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
}

#one:checked ~ label.first .circle,
#two:checked ~ label.second .circle,
#three:checked ~ label.third .circle {
    border-color: #e73a56;
    background: #fff;
}

.card label.box .plan {
    display: flex;
    width: 100%;
    align-items: center;
}

.card input[type="radio"] {
    display: none;
}

// .component-library {
//     display: flex !important;
//     flex-direction: column !important;
//     justify-content: center !important;
//     align-items: center !important;
// }

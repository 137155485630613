.sales-container{
    width: 100%;
    height: 10vh;
   
}

.check-box{
    display: flex;
   width: 80%;
    // border: 1px solid;
    justify-content: center;
    gap: 1rem;

}

.check-all{
    display: flex;
    align-items: center;
    // gap: 1rem;
}

.checkboxes-from-to{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.6rem;
}

.staff-profile{
    display: flex;
    gap: 0.5rem;
}
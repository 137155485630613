.Main-cont{
    position: relative;
 }
 
 
 
 .Education-cont{
     position: relative;
     display: flex;
     padding: 1rem;
     // border: 1px solid;
     justify-content: space-between;
     align-items: center;
     width: 100%;
 }
 
 .Edit-session{
     display: flex;
     align-items: center;
 }
 
 .edu-textfields{
     display: flex;
     flex-direction: column;
     position: relative;
     padding: 1rem;
     margin-top: 0.5rem;
     width: 100%;
     // border: 1px solid;
 }
 
 .deg-spe{
     display: flex;
     // border: 1px solid;
     width: 100%;
     align-items: center;
     gap: 10rem;
     // justify-content: space-between;
     margin-top: 1rem;
 }
 
 .A-B-C{
     display: flex;
     // border: 1px solid;
     width: 100%;
     gap: 10rem;
     align-items: center;
     // justify-content: space-between;
 
 }
 
 .A-B-C1{
     display: flex;
     // border: 1px solid;
     width: 100%;
     align-items: center;
     gap: 10rem;
     // justify-content: space-between;
 
 }
 
 .Education-cont1{
     position: relative;
     display: flex;
     padding: 1rem;
     margin-top: 1.5rem;
     // gap: 1rem;
     // border: 1px solid;
     justify-content: space-between;
     align-items: center;
     width: 100%;
 }
 
 .medical-card{
     display: flex;
     // flex-direction: column;
     position: relative;
     margin-top: 2rem;
     border-radius: 8px;
     padding: 1rem;
     gap: 1rem;
     border:1px solid  #E6E1E5 ;
     width: 100%;
 }
 
 .medical-details{
     display: flex ;
     flex-direction: column;
     // border: 1px solid;
     width: 100%;
 }
 
 .date{
     display: flex;
     // border: 1px solid;
     width: 100%;
     justify-content: flex-start;
     margin-top: 2rem;
 }
 
 .date1{
     display: flex;
     // border: 1px solid;
     width: 100%;
     justify-content: flex-start;
 }
 
 .job-title{
     display: flex;
     // border: 1px solid;
     flex-direction: column;
     width: 100%;
     margin-top: 2rem;
     justify-content: space-between;
 }
 .edit-icon{
     display: flex;
     // border: 1px solid;
     align-items: center;
 }
 
 .Hospital-name{
     // border: 1px solid;
     width: 100%;
     display: flex;
     justify-content: flex-start;
     margin-top: -0.5rem;
 }
 
 .description{
     display: flex;
     // border: 1px solid;
     width: 100%;
     justify-content: flex-start;
     margin-top: 1rem;
 }
 
 .des-info{
     display: flex;
     // border: 1px solid;
     align-items: center;
     justify-content: flex-start;
     width: 100%;
     color: grey;
 
 }
 
 .textfield-cont{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     padding: 1rem;
     flex-direction: column;
 }
 
 .input-field{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     justify-content: space-between;
     gap: 2rem;
 }
 
 .date-picker{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     justify-content: space-between;
     gap: 2rem;
     margin-top: 1rem;
 }
 
 .save-dlt-btn{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     margin-top: 20rem;
     justify-content: space-between;
 }
 
 .save-dlt-btn1{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     margin-top: 5rem;
     justify-content: space-between;
 }
 
 .save-btn1{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     margin-top: 6rem;
     justify-content: flex-end;
 }
 
 .custom-list{
     display: flex;
     position: relative;
     // border: 1px solid;
     width: 100%;
     justify-content: space-between;
     gap: 2rem;
     color: #939094;
 }
 
 .descrip{
     display: flex;
     position: relative;
     // border: 1px solid;
     margin-top: 1.5rem;
     width: 100%;
     color: #939094;
 
 }
 
 .text{
     display: flex;
     position: relative;
     // border: 1px solid;
     border:1px solid  #E6E1E5 ;
     margin-top: 1.5rem;
     width: 100%;
     padding: 1rem;
     color: #939094;
     border-radius: 8px;
 }
 
 .dlt{
     display: flex;
     align-items: center;
     // border: 1px solid;
 }
.nav-d-f-container{
    // border: 1px solid;
    width: 100%;
   display: flex;
   align-items: center;
  justify-content: flex-end;
}

.doctor-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
    position: relative;
    border: 1px solid #E6E1E5;
    border-radius: 8px;
    margin-top: 3rem;
}


.usage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .component-library {
        margin: 20px 50px;
        text-align: center;

        .items {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 10px;
        }
    }
}

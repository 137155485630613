.NavBar-Box-profile > a {
    text-decoration: none;
    margin: 0.7%;
    padding: 10px 16px;
    border-radius: 8px;
    color: #313033;
    cursor: pointer;
    
}

.NavBar-Box-profile a.active {
    background-color: #e72b4a;
    color: white;
}

// Base
$background-color: #f0f0f0;

// Backgrounds
$primary-background: #ffffff;
$grey-background: #e6e1e5;
$disabled-background: #c9c5ca;

// Theme
$primary-color: #e72b4a;
$secondary-color: #e9405c;
$teritiary-color: #fad5db;
$pre-white-color: #efefef;
$white-color: #ffffff;
$black-color: #000000;
$light-grey-color: #939094;

// Form & Typography
$label-color: #0a0a26;
$default-text-color: #495057;
$placeholder-color: #a1a8c3;
$invalid-color: #dc362e;
$valid-color: #2ad159;
$caution-color: #e7df33;
$heading-color: #434349;
$primary-text-color: #1c1b1f;
$secondary-text-color: #49454f;
$teritiary-text-color: #aeaaae;
$box-shadow-color: 0px 4px 6px rgba(0, 0, 0, 0.25);

// Action
$red-color: #b3261e;
$green-color: #22a747;
$btn-border-radius: 12px;
$selected-background-color: #fdedea;

// Input

// Chips
$input-chip-text-color: #45170d;
$input-chip-border-radius: 30px;

// Enabled
$input-background: #ffffff;
$input-chip-border-color: #939094;
$input-chip-transparent-text-color: #787579;

$font-family: "Poppins";
$font-style: normal;

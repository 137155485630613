.superadmindoctor-card{
    display: flex;
    // flex-direction: column;
    width: 100%;
    padding: 1rem;
    border: 1px solid #E6E1E5;
    border-top: 1px;
    border-left: 1px;
    border-right: 1px;
    gap: 0.5rem;

}

.buttons-texts{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

}

.name-approve{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
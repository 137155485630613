.Cash-out{
    position: relative;
    display: flex;
    width: 100%;
    // border: 1px solid;
    padding: 1rem;
    margin-top: 1rem;
    justify-content: flex-start;
}

.Text-Amount{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid red;
    padding: 3rem;
    border-radius: 10px;
    background: #E72B4A;
    margin-top: 1rem;
}

.Request-cashout{
   position: relative;
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   border: 1px solid  #E6E1E5;
   margin-top: 1rem;
   padding: 2rem;
}

.Text{
    // border: 1px solid;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.Table-t{
    position: relative;
    border: 1px solid  #E6E1E5;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    width: 100%;
    padding: 2rem;
}

.payout-main-container{
    width: 100%;
    margin-top: 2%;
}




@media only screen and (max-width: 768px) {
    .mui-date-time-pickers {
       display: flex;
       flex-direction: column;
       width: 100vw;
       justify-content: center;
    }

   .days{
   width: 60%;
//    max-width: 360px;
   }

   .time{
    width: 100%;
    display: flex;
    // max-width: 360px;
   }

    .Date-range-picker1 .MuiTextField-root,
    .time-picker .MuiTextField-root {
        width: 100%; /* Make the text fields inside the pickers take full width */
    }
}
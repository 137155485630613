#fileInput{
    display: none;
  }
  #icon{
    width: 1em;
    cursor: pointer;
  }

  #upload-image-loader{
    margin-top: 5%;
  }
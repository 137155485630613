body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // overflow-x: hidden;
    // overflow-y: hidden;
    background-color: aliceblue;
}

.logo2 {
    margin: auto;
}

.form-group6 {
    margin-left: 42.5%;
}

.field-center6 {
    margin-top: 3%;
}
.container {
    background-color: aliceblue;
    width: 100vw;
    height: 100vh;
}

// @media only screen and (max-width: 600px) {
//     .container {
//       background-color: green;
//     }
//   }

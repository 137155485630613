// @import "./static/scss/base/variables";
@use "./static/scss/base/variables" as *;

html{
    background: #ffff;
}

#app {
    background-color: #ffff;
}

.MuiButton-root.MuiButton-contained,
.MuiButton-root.MuiButton-outlined {
    border-radius: 12px;
    padding: 8px 24px;
    text-transform: capitalize;
}

.MuiButton-root.MuiButton-text {
    text-transform: capitalize;
}

.MuiButton-root.MuiButton-contained.elevation,
.MuiButton-root.MuiButton-outlined.elevation {
    box-shadow: $box-shadow-color;
}

.MuiButton-root.MuiButton-contained {
    &:hover {
        background: $secondary-color;
    }
}

.MuiButton-root.MuiButton-outlined {
    &:hover {
        background: $teritiary-color;
    }
}

.MuiChip-root.MuiChip-filled.elevation {
    box-shadow: $box-shadow-color;
}

.MuiChip-root.MuiChip-filled {
    background: $teritiary-color;
    color: $input-chip-text-color;
    text-transform: capitalize;
    padding: 12px;

    &:hover {
        background: $teritiary-color;
    }
}

.MuiChip-root.MuiChip-outlined {
    border-color: $input-chip-border-color;
    color: $input-chip-transparent-text-color;
    text-transform: capitalize;
}

.MuiChip-root.MuiChip-outlined .MuiChip-deleteIcon {
    color: $input-chip-transparent-text-color !important;
}

.MuiChip-root .MuiChip-deleteIcon {
    color: $input-chip-text-color !important;
}

.MuiInputLabel-root,
.MuiFormHelperText-root,
.MuiInputLabel-root.Mui-focused,
.MuiInputAdornment-root {
    color: $primary-text-color !important;
    text-transform: capitalize;
}

.MuiTextField-root.valid-class {
    .MuiFormHelperText-root {
        color: $valid-color !important;
    }
}

.MuiTextField-root.invalid-class {
    .MuiFormHelperText-root {
        color: $invalid-color !important;
    }
}

.Mui-disabled {
    color: $teritiary-text-color !important;
}

.MuiTabs-root {
    .MuiButtonBase-root {
        text-transform: capitalize;
    }
}

.MuiList-root {
    .MuiListItem-root {
        .MuiButtonBase-root.MuiListItemButton-root {
            align-items: flex-start;
            display: flex;
            &:hover {
                background-color: transparent;
            }

            .MuiListItemText-root {
                margin: 0;
            }
        }
    }
}

.MuiSelect-select.MuiInputBase-input {
    &:focus {
        background-color: transparent;
    }
}

.MuiPaper-root.MuiMenu-paper {
    background-color: $white-color;

    .MuiList-root.MuiMenu-list {
        .MuiButtonBase-root.MuiMenuItem-root {
            text-transform: capitalize;

            &:not(.Mui-selected):hover {
                color: $white-color;
            }
        }
    }
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.fab-icon-class {
    &:before,
    &:after {
        border: none !important;
    }

    .MuiButtonBase-root.MuiIconButton-root {
        &:hover {
            background-color: $primary-color;
        }
    }
}

.MuiSelect-select.MuiInputBase-input.MuiInput-input {
    text-align: left;
    text-transform: capitalize;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
    padding-top: 0;
}

.MuiDrawer-root {
    color: $primary-color;
    .MuiPaper-root {
        background-color: $white-color;
        .MuiList-root {
            padding: 20px;
            .MuiListItem-root {
                .MuiButtonBase-root.MuiListItemButton-root {
                    align-items: center;
                }
            }
            
            .MuiButtonBase-root.MuiListItemButton-root {
                .MuiListItemIcon-root {
                    color: $primary-color;
                }
            }
            .MuiListItem-root.active {
                background-color: $primary-color;
                color: $white-color;
                border-radius: 12px;

                .MuiButtonBase-root.MuiListItemButton-root {
                    .MuiListItemIcon-root {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

.MuiBox-root {
    .MuiPaper-root {
        .MuiToolbar-root {
            .MuiButtonBase-root.MuiIconButton-root {
                margin-right: 10px;
            }
            .MuiTypography-root {
                text-transform: capitalize;
            }
        }
    }
}

.MuiLinearProgress-root.MuiLinearProgress-determinate {
    background-color: $disabled-background;
}

.MuiDialog-root.MuiModal-root {
    .MuiDialog-container {
        .MuiPaper-root {
            background-color: $white-color;
            border-radius: 12px;
            .MuiTypography-root.MuiDialogTitle-root {
                text-transform: capitalize;
                text-align: center;
            }
            .MuiButtonBase-root.MuiIconButton-root {
                color: $primary-text-color;
            }
            .MuiDialogContent-root {
                padding: 0 30px;
            }
        }
    }
}

.MuiSnackbar-root {
    .MuiPaper-root.MuiSnackbarContent-root {
        background-color: $white-color;
        color: $primary-text-color;
        text-transform: capitalize;
    }
}
body {
    font-family: "Poppins";
}

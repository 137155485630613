.participant-view-corner-display-name-container {
    position: absolute;
    bottom: 0.5rem; /* Adjust as needed */
    left: 0.5rem; /* Adjust as needed */
    border-radius: 0.375rem; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem; /* Adjust as needed */
  }
  

  .participant-view-corner-display-name-paragraph {
    font-size: 0.875rem; /* Adjust as needed */
    color: #fff; /* Adjust as needed */
    margin-left: 0.125rem; /* Adjust as needed */
  }
  

  .participant-view-stream-container {
    position: absolute;
    top: 0.5rem; /* Adjust as needed */
    right: 0.5rem; /* Adjust as needed */
    border-radius: 0.375rem; /* Adjust as needed */
    padding: 0.5rem; /* Adjust as needed */
    cursor: pointer;
  }
  

  .participant-view-stream-popover {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem; /* Adjust as needed */
    cursor: pointer;
  }
  

  .participant-view-stream-popover-panel{
    position: absolute;
  }

  .participant-view-stream-popover-inner-container {
    background-color: #4b5563; /* Adjust as needed */
    border-radius: 0.5rem; /* Adjust as needed */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Adjust as needed */
    border: 1px solid #0000001a; /* Adjust as needed */
  }

  .participant-view-stream-popover-inner-container-two {
    padding: 0.5625rem; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.375rem 0.375rem 0 0; /* Adjust as needed */
  }
  
  .participant-view-stream-popover-inner-container-two-paragraph {
    font-size: 0.875rem; /* Adjust as needed */
    color: #fff; /* Adjust as needed */
    font-weight: 600; /* Adjust as needed */
  }
  
  .participant-view-stream-popover-inner-container-two-button {
    cursor: pointer;
    color: #fff;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 9999px;
    text-align: center;
  }
  
  .participant-view-stream-popover-inner-container-two-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .participant-view-stream-popover-inner-container-three {
    display: flex;
  }
  
  .participant-view-stream-popover-inner-container-three-inner-container {
    display: flex;
    flex-direction: column;
  }
  
  .participant-view-stream-popover-inner-container-three-inner-container-two {
    display: flex;
    flex: 1;
    align-items: center;
    width: 120px;
  }
  
  .participant-view-stream-popover-inner-container-three-inner-container-two-paragraph {
    font-size: 0.75rem; /* Adjust as needed */
    color: #fff; /* Adjust as needed */
    margin-top: 0.375rem; /* Adjust as needed */
    margin-left: 0.5rem; /* Adjust as needed */
  }
  
  .participant-view-stream-popover-inner-container-three-inner-container-three {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  
  .participant-view-stream-popover-inner-container-three-inner-container-three-paragraph {
    font-size: 0.75rem; /* Adjust as needed */
    color: #fff; /* Adjust as needed */
    margin-top: 0.375rem; /* Adjust as needed */
    width: 80px; /* Adjust as needed */
    text-align: center;
  }

  .participant-view-stream-popover-inner-container-three-inner-container-four {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .participant-view-stream-popover-inner-container-three-inner-container-four-paragraph {
    font-size: 0.75rem; /* Adjust as needed */
    color: #fff; /* Adjust as needed */
    margin-top: 0.375rem; /* Adjust as needed */
    width: 80px; /* Adjust as needed */
    text-align: center;
  }
  


  
  
.not-found {
    font-size: 100%;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    top: 20%;
    position: absolute;
    left: 40%;

    p {
        font-size: 2em;
        text-align: center;
        font-weight: 100;
    }

    h1 {
        text-align: center;
        font-size: 15em;
        font-weight: 100;
    }
}

// App imports
@use "base/variables" as *;

/* override browser default */
html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: $font-family;
    font-style: $font-style;
    height: 100vh;
    width: 100vw;
    background-color: $background-color !important;
}

/* include border and padding in element width and height */
* {
    box-sizing: border-box;
    // justify-items: center;
}

.large-heading-1 {
    font-weight: 600;
    font-size: 64px;
    line-height: 94px;
    color: $label-color;
}

.large-heading-2 {
    font-weight: 500;
    font-size: 48px;
    line-height: 74px;
    color: $label-color;
}

.large-heading-3 {
    font-weight: 500;
    font-size: 36px;
    line-height: 64px;
    color: $label-color;
}

.large-heading-4 {
    font-weight: 500;
    font-size: 28px;
    line-height: 64px;
    color: $label-color;
}

.heading-1 {
    font-weight: 400;
    font-size: 34px;
    line-height: 46px;
    color: $label-color;
}

.heading-2 {
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    color: $label-color;
}

.heading-3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: $label-color;
}

.heading-4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $label-color;
}

.large-title {
    color: $label-color;
    font-weight: 400;

    .title-1 {
        font-size: 24px;
        line-height: 38px;
    }

    .title-2 {
        font-size: 18px;
        line-height: 28px;
    }

    .title-3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .title-4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }
}

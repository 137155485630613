.meeting-container-main{
    position: fixed;
    inset: 0;
  }

  .meeting-inner-container-one {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #4b5563; /* Adjust as needed */

    .meeting-time {
      color: red;
      font-size: bold;
      position: fixed;
      z-index: 1;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
    }

  }


  

  .meeting-inner-container-two {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: #28282B;
    height: 100%;
  }
  
  .meeting-inner-container-three {
    display: flex;
    flex: 1;
  }
  
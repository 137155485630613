.field-center1 {
    margin-left: 6%;
    margin-top: 5%;
}

.form-group1 {
    margin-left: 38%;
}

#container{
    background: #ffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#inner-container{

    height: 60%;
}
@use "../../static/scss/base/variables" as *;

.toggle-switch {
    position: relative;
    width: 60px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    &-checkbox {
        display: none;
    }

    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid $grey-background;
        border-radius: 20px;
        margin: 0;
    }

    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;

        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 34px;
            padding: 0;
            line-height: 34px;
            font-size: 14px;
            color: $white-color;
            font-weight: bold;
            box-sizing: border-box;
        }

        &:before {
            content: ""; // Yes
            text-transform: uppercase;
            padding-left: 10px;
            background-color: $primary-color;
            color: $white-color;
        }
    }

    &-disabled {
        background-color: $disabled-background;
        cursor: not-allowed;

        &:before {
            background-color: $disabled-background;
            cursor: not-allowed;
        }
    }

    &-inner:after {
        content: ""; // No
        text-transform: uppercase;
        padding-right: 10px;
        background-color: $grey-background;
        color: $white-color;
        text-align: right;
    }

    &-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        margin: 5px;
        background: $teritiary-text-color;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 25px;
        border: 0 solid $grey-background;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;

        .disabled {
            color: $disabled-background !important;
        }
        span {
            font-size: 20px;
            color: $grey-background;
        }
    }

    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }

        .toggle-switch-switch {
            right: 0px;
            background: $pre-white-color;

            span {
                color: $primary-color;
            }
        }
    }
}


.NavBar-Container-patient-reports {
    display: flex;
    // // border: 1px solid black;
    // width: 73vw;
    // position: absolute;
}

.NavBar-Container-patient-reports > a {
    text-decoration: none;
    margin: 0.7%;
    // border:  1px solid #E72B4A;
    padding: 4px 20px;
    border-radius: 8px;
    gap: 16px;
    padding: 10px 16px;
    color: "#313033";
    cursor: pointer;
    // border: "10px solid black";
    // width: "100%";
}

.NavBar-Container-patient-reports a.active {
    background-color: #e72b4a;
    padding: 10px 16px;
    color: white;
}

// .otpsent {
//     // margin-left: 33%;
// }
.forgotPassword {
    // margin-left: 37%;
    display: flex;
    flex-direction: column;
    // margin-top: 10%;
    // border: 2px solid red;
    align-items: center;
    // height: 60%;
    justify-content: center;
    // width: 100%;
    text-align: -webkit-center;
}
// .text-center2 {
//     // margin-left: 38%;
// }
.resendCode {
    display: flex;
    justify-content: center;
    color: #e72b4a;
    width: 100%;
    align-items: center;
}

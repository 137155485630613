#fileInput {
    display: none;
}
#icon {
    width: 100px;
    cursor: pointer;
}

.icon-button > span{
    margin-right: 0px;
    margin-left: 0px;
}

.chat-container-one{
    width: 25%;
    border: 1px solid black;
    height: 75%;
}

.chat-container-two{
    width: 75%;
    border: 1px solid red;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.message-btn-container{
    height: 10%;
}

.text-message-container{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    transition: top 0.3s;
}
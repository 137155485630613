.NavBar-Box-one {
    // margin-bottom: 8%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: -1%;
    white-space: nowrap;
    width: fit-content;
    // width: auto;
}

.NavBar-Box-one > a {
    text-decoration: none;
    margin: 1%;
    padding: 10px 16px;
    border-radius: 8px;
    color: #e72b4a;
    font-family: "Poppins";
    cursor: pointer;
    // width: auto;
    // width: -webkit-fill-available;
}

.NavBar-Box-one a.active {
    text-decoration: none;
    margin: 0.7%;
    padding: 10px 16px;
    border-radius: 16px;
    font-family: "Poppins";
    cursor: pointer;
    background-color: #e72b4a;
    color: white;
    width: 8em;
    width: auto;
}






.main-container{
    position: relative;
    margin-top: 2rem;
    width: 100%;
}

.Doctor-detail-container{
    display: flex;
    border-radius: 8px;
    margin-top: 0.5rem;
    margin-left: 0;
    border: 1px solid  #E6E1E5;
    position: relative;
    width: 90%;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;

    .doc-profile {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

}

.image-container{
    width: 70px;
    height: 70px;
    padding: 1%;
    border-radius: 8px;
    margin-left: 0%;
    margin-top: 0%;
}

.Detail-container{
     // border:1px solid;
    // height:60%;
    margin-top: 1%;
}

.Edit-icon{
    // border:'1px solid',
    height: 40px;
    width: 40px;
}

.Edit-btn{
     // border:'1px solid',
}

.Add-container{
    display: flex;
    position: relative;
    width: 90%;
    // border: 1px solid  #E6E1E5;
    height: 100%;
    margin-top: 2rem;
    align-items: center;
    justify-content: space-between;
}

.Add-addicon{
    display: flex;
    margin-top: 0rem;
    color: #E72B4A;
    // border: 1px solid;

}

.Add-btn{
//    border: 1px solid;
//    border-bottom: 1px;
}



.Box1{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border: 1px solid  #E6E1E5;
    height: 100%;
    margin-top: 1rem;
    padding: 2rem;
    border-top: 1px;
    border-left: 1px;
    border-right: 1px;
}

.Box2{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border: 1px solid  #E6E1E5;
    margin-top: 0rem;
    padding: 2rem;
    border-top: 1px;
    border-left: 1px;
    border-right: 1px;
    height: 100%;
}

.Box3{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border: 1px solid  #E6E1E5;
    margin-top: 0rem;
    padding: 2rem;
    border-top: 1px;
    border-left: 1px;
    border-right: 1px;
    height: 100%;
}



.Delete-Edit{
    display: flex;
    color: #E72B4A;
}

.Delete-Icon{
    display: flex;
    color: #E72B4A;
    
}

.Edit-Icon{
    display: flex;
    color: #E72B4A;
}

.first-plan{
    width: 100%;
    display: flex;
    align-items: center;
}

.second-plan{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.third-plan{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.first-plan-content{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.second-plan-content{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.third-plan-content{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.save-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}





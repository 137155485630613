@use "../../static/scss/base/variables" as *;
@use "../../static/scss/main.scss" as *;

.usage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .component-library {
        margin: 20px 50px;
        text-align: center;
        width: 100%;

        .items {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 10px;
            width: 100%;
        }
    }
}
